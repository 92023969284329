/*

	Last rev: 08/27/21

	Authors: Liam McBain

	YAGOS

*/

import * as Scoreboard from './Scoreboard'

export class GraphicUpdater {
    constructor(dom){
        this.dom = dom || document;
        this.action_list = {
            'update':'text'
        }
    }
    Update(data){
        data.forEach((x,i)=>{
            switch(x.action){
                case('update_text'):
                    update_text(x.value,x.class,this.dom)
                break;
                case('update_color'):
                    update_color(x.value,x.class,this.dom)
                break;
                case('update_image'):
                    update_image(x.value,x.class,this.dom)
                break;
                case('animate'):
                    animate(x.class,this.dom)
                break;
            }
        })
    }
    StreamRTC(wrtc_conn){

    }
    SetScoreboardBinds(callback){
        // Need to figure out how to do this so as to avoid updating fully and using manual getters and setters
        Scoreboard.SetHockeyBinds(this.dom,callback)
    }

    /* THESE SHOULD BE UPDATED WITH THE CLASS NAMES SUCH THAT IT CAN CALL THE CLASS BASED ON NAME; GENERALIZATION */
    FullScoreboardUpdate(scoreboard){
        switch(scoreboard.sport){
            case ('Hockey'):
                Scoreboard.UpdateHockeyScoreboard(scoreboard,this.dom)
            break;
            default:

            break;
        }
        UpdateScoreboardInfo(scoreboard,this.dom)
    }
    FormatScoreboardTimers(scoreboard){
        switch(scoreboard.sport){
            case ('Hockey'):
                Scoreboard.UpdateHockeyTimers(scoreboard,this.dom)
            break;
            default:

            break;
        }
    }
}

export function UpdateScoreboardInfo(scbd,dom){
    if(scbd.hasOwnProperty('home_team_info')){
        if(scbd.home_team_info.attributes){
            var hh1
            var hh2
            update_text(scbd.home_team_info.attributes.abbreviation,'home_abbreviation',dom)

            if(scbd.home_team_info.attributes.primary_color.charAt(0)!='#'){hh1='#'}else{hh1=''}
            if(scbd.home_team_info.attributes.secondary_color.charAt(0)!='#'){hh2='#'}else{hh2=''}

            update_color(hh1+scbd.home_team_info.attributes.primary_color,'home_color1',dom)
            update_color(hh2+scbd.home_team_info.attributes.secondary_color,'home_color2',dom)
            update_image(`${process.env.VUE_APP_RPI_API_URL}${scbd.home_team_info.attributes.primary_logo.data.attributes.url}`,`home_logo1`,dom)
        }
        if(scbd.away_team_info.attributes){
            var ah1
            var ah2
            update_text(scbd.away_team_info.attributes.abbreviation,'away_abbreviation',dom)

            if(scbd.away_team_info.attributes.primary_color.charAt(0)!='#'){ah1='#'}else{ah1=''}
            if(scbd.away_team_info.attributes.secondary_color.charAt(0)!='#'){ah2='#'}else{ah2=''}

            update_color(ah1+scbd.away_team_info.attributes.primary_color,'away_color1',dom)
            update_color(ah2+scbd.away_team_info.attributes.secondary_color,'away_color2',dom)
            update_image(`${process.env.VUE_APP_RPI_API_URL}${scbd.away_team_info.attributes.primary_logo.data.attributes.url}`,`away_logo1`,dom)
        }
        // for(var i=1;i<5;i++){
        //     update_text(scbd.home_team_info[`name${i}`],`home_name${i}`,dom)
        //     update_text(scbd.away_team_info[`name${i}`],`away_name${i}`,dom)
        // }
        // for(var i=1;i<5;i++){
        //     update_color(scbd.home_team_info[`color${i}`],`home_color${i}`,dom)
        //     update_color(scbd.away_team_info[`color${i}`],`away_color${i}`,dom)
        // }
        // for(var i=1;i<5;i++){
        //     update_image(scbd.home_team_info[`logo${i}`],`home_logo${i}`,dom)
        //     update_image(scbd.away_team_info[`logo${i}`],`away_logo${i}`,dom)
        // }
    }
}

export function FormatClock (clock) {
    var out;
    if(clock.min>0){
		if(clock.sec>9){
			out = `${clock.min}:${clock.sec}`;
		}
		else{
			out = `${clock.min}:0${clock.sec}`;
		}
	}
	else{
		out = `${clock.sec}.${clock.ms}`;
	}
    return out;
}

export function mod_elements_by_class ( item , dom , callback ) {
    var a = dom.$el.querySelectorAll(`.${item}`);
    for (var i=0,len=a.length;i<len;i++){
        callback(a[i]);
    }
}

export function update_text ( text , id , dom ) {
	mod_elements_by_class( id , dom , function( elem ) {
		elem.innerHTML = text;
	});
}

export function update_color ( color , id , dom ) {
    mod_elements_by_class( id , dom , function( elem ) {
		if(elem.tagName=="stop"){
            elem.style["stop-color"] = color;
        }
        else if(elem.tagName == "INPUT"){
            elem.value = color;
        }
        else{
            elem.style.fill = color;
        }
	});
}

export function update_image ( path , id , dom ) {
    mod_elements_by_class( id , dom , function( elem ) {
		if(elem.tagName == "IMG"){
            elem.src = path;
        }
        else{
            elem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', path);
        }
	});
}

export function animate ( id , dom ) {
    mod_elements_by_class( id , dom , function( elem ) {
		elem.beginElement()
	});
}

export function hide ( id , dom ) {
    mod_elements_by_class( id , dom , function( elem ) {
        elem.style.display='none'
    })
}

export function show ( id , dom ) {
    mod_elements_by_class( id , dom , function( elem ) {
        elem.style.display='inline'
    })
}