import * as hockey from './sport/Hockey'

export const Hockey = hockey.Scoreboard
export const SetHockeyBinds = hockey.SetBinds
export const UpdateHockeyScoreboard = hockey.UpdateScoreboard
export const UpdateHockeyTimers = hockey.UpdateTimers
// THESE FUNCTIONS NEED TO LIVE IN THIS FILE, NOT THEIR RESPECTIVE SPORT FILES

export const assign_remove_functions = function(target,obj) {
	var ret = target;
    for (var property in obj) {
        if (obj.hasOwnProperty(property)) {
            if (typeof obj[property] == "object" && typeof obj[property] !== "undefined"){
                if (Array.isArray(ret)){
                    ret[property]={};
                    target[property]={};
                }
                ret[property] = assign_remove_functions(target[property],obj[property]);
            }
            else{
            	//console.log(typeof obj[property] + "  " + property);
                if (typeof obj[property] !== "undefined"){
                	ret[property] = obj[property];
                }
            }
        }
    }
    return ret;
}
