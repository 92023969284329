/******* Communication handling ***********/
/* FOR IN BROWSER UNLESS OTHERWISE STATED */

/* WebSockets */

export class WebSocketHandler {
    constructor(url,user,event,scoreboard_callback,information_callback){
        this.url = url || `ws://localhost`
        this.ws
        this.scbd
        this.game_id
        this.user = user
        this.event = event
        this._scoreboard_callback = scoreboard_callback || function(){}
        this._information_callback = information_callback || function(){}
    }
    sendWSAction(action,params = []){
        var data = {
            context: 'scoreboard',
            user: this.user,
            event: {
                event_id: this.event
            },
            action: action,
            game: this.scbd,
            body: this.scbd,
            params: params
        }
        this.ws.send(JSON.stringify(data))
    }
    
    sendWSInformation(info){
        var data = {
            context: 'information',
            user: this.user,
            event: {
                event_id: this.event
            },
            action: 'RefreshData',
            body: info,
            game: this.scbd
        }
        this.ws.send(JSON.stringify(data))
    }
    
    retryWSConnection(){
        var that=this
        setInterval(function(){
            if(that.ws.readyState===3){that.newWSConnection()}
        },5000)
    }
    
    newWSConnection(){
        var that = this
        this.ws = new WebSocket(`${this.url}`)
        this.ws.onopen = function open(){
            var data = {
                context: 'init',
                user: that.user,
                event: {
                    event_id: that.event
                }
            }
            that.ws.send(JSON.stringify(data))
            console.log('Connected to server')
        }
        this.ws.onmessage = function incoming(data){
            var pd = JSON.parse(data.data)
            switch(pd.context){
                case 'scoreboard':
                    that._scoreboard_callback(pd)
                break;
                case 'information':
                    that._information_callback(pd)
                break;
            }
        }
        this.ws.onclose = function close(){
            console.log('Connection closed')
        }
    }

    set scoreboard_callback(x){
        this._scoreboard_callback = x
    }
    set information_callback(x){
        this._information_callback = x
    }
}

/* WebRTC */
