<template>
    <div class="graphics container">
        <!-- <object type="image/svg+xml" data="scoreboard.svg"></object> -->
        <graphic :style="`position:fixed;top:${scene.graphics.scoreboard.y}px;left:${scene.graphics.scoreboard.x}px;`" ref="scoreboard" :img="scoreboard_graphic"></graphic>
        <!-- <graphic :style="`position:fixed;left:${scene.graphics.intermission.x};top:${scene.graphics.intermission.y}`" ref="intermission" :img="intermission_graphic"></graphic> -->
        <!-- <graphic :style="`position:fixed;left:${scene.graphics.player_info.x};top:${scene.graphics.player_info.y}`" ref="player_info" :img="player_info_graphic"></graphic> -->
    </div>
</template>

<script>
    import * as Score from '../yagos-lib/Scoreboard'
    import * as Data from '../yagos-lib/Data'
    import graphic from '../components/graphic'
    import axios from 'axios'
    export default{
        components: {
            graphic
        },
        data () {
            var ws = new Data.WebSocketHandler(process.env.VUE_APP_WS_URL,{user_id:0},0)
            return{
                scoreboard: new Score.Hockey(20,20),
                overlay: {overlay_layout:{
                    graphics:[]
                }},
                ws: ws,
                scoreboard_graphic: 'fdsafdsafd',

                scene: {
                    resolution: {
                        name: "1080p",
                        x: 1920,
                        y: 1080
                    },
                    graphics: {
                        scoreboard: {
                            x: null,
                            y: 50,
                            visible: true
                        },
                        intermission: {
                            x: null,
                            y: 0,
                            visible: true
                        },
                        player_info: {
                            x: 30,
                            y: 500,
                            visible: false
                        }
                    }
                },
                ping: null

            }
        },
        beforeCreate () {
            
        },
        async mounted () {
            await axios
                .get(`/scoreboard.svg`, {
                    headers: {
                    }
                })
                .then(res => {
                    this.scoreboard_graphic = res.data
                })
                console.log(this.scoreboard_graphic)
            var that=this
            console.log(that.$refs)
            this.scoreboard.timer = function (){
                for(var id in that.$refs){
                    that.$refs[id].upd.FormatScoreboardTimers(that.scoreboard)
                }
            }
            this.scoreboard.event = function (event){
                for(var id in that.$refs){
                    that.$refs[id].upd.Update([{class: event,action:'animate',value:true}])
                    that.scoreboard.RefreshData()
                }
            }
            this.scoreboard.update = function (){
                for(var id in that.$refs){
                    that.$refs[id].upd.FullScoreboardUpdate(that.scoreboard)
                }
            }
            this.scoreboard.refresh = function (){
                for(var id in that.$refs){
                    console.log(that.$refs[id])
                    that.$refs[id].upd.FullScoreboardUpdate(that.scoreboard)
                }
            }
            this.ws.scoreboard_callback = function(pd){
                if(pd.action=='RefreshData'){
                    var t = pd.body
                    var temp = new Score.Hockey(t._period_length,t._overtime_length)
                    that.scoreboard = Score.assign_remove_functions(temp,t)
                    if(!that.scoreboard.clock.pause){
                        that.scoreboard.clock.start()
                    }
                    that.scoreboard.timer = function (){
                        for(var id in that.$refs){
                            that.$refs[id].upd.FormatScoreboardTimers(that.scoreboard)
                        }
                    }
                    that.scoreboard.event = function (event){
                        for(var id in that.$refs){
                            that.$refs[id].upd.Update([{class: event,action:'animate',value:true}])
                            that.scoreboard.RefreshData()
                        }
                    }
                    that.scoreboard.update = function (){
                        for(var id in that.$refs){
                            that.$refs[id].upd.FullScoreboardUpdate(that.scoreboard)
                        }
                    }
                    that.scoreboard.refresh = function (){
                        for(var id in that.$refs){
                            that.$refs[id].upd.FullScoreboardUpdate(that.scoreboard)
                        }
                    }
                    that.scoreboard.HandleEvent(pd.action,pd.params)
                }
                console.log(pd.action)
                that.scoreboard.HandleEvent(pd.action,pd.params)
            }
            this.ws.information_callback = function(pd){
                console.log(pd)
            }
            this.ready=true
            this.ws.newWSConnection()
            this.ws.retryWSConnection()

            this.ping = setInterval(()=>{
                this.ws.sendWSInformation('ping')
                console.log('ping')
            },10000)
        },
        methods: {
        }
        
    }
</script>