<template>
    <div class="control">
        <nav>
            <router-link to="/">Home</router-link> |
            <router-link to="/graphics">Graphics</router-link>
        </nav>
        <ul class="nav nav-tabs justify-content-center">
            <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#scoreboard">Scoreboard</a></li>
            <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#game">Game Settings</a></li>
            <li class="nav-item"><a class="nav-link" data-bs-toggle="tab" href="#settings">General Settings</a></li>
        </ul>
        <div class="tab-content">
            <div id="scoreboard" class="tab-pane fade">
                <hockey ref="scoreboard"></hockey>
            </div>
            <div id="game" class="tab-pane fade">
                <div class="row">
                    <div class="col"><h1>HOME</h1>
                        <select class="form-select" v-model="home" @change="change_home">
                            <option v-for="o in teams" :value="o">{{o.attributes.school}}</option>
                        </select>

                        <!-- INFO -->
                        <div v-if="home">
                            <img class="logo" :src="`${apiurl}${home.attributes.primary_logo.data.attributes.url}`"/>
                        </div>
                    </div>
                    <div class="col"><h1>AWAY</h1>
                        <select class="form-select" v-model="away" @change="change_away">
                            <option v-for="o in teams" :value="o">{{o.attributes.school}}</option>
                        </select>

                        <!-- INFO -->
                        <div v-if="away">
                            <img class="logo" :src="`${apiurl}${away.attributes.primary_logo.data.attributes.url}`"/>
                        </div>
                    </div>
                </div>
                
            </div>
            <div id="settings" class="tab-pane fade">
                <select class="form-select" v-model="scene.resolution" @change="update_scene">
                    <option v-for="r in res" :value="r">{{r.name}}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import hockey from "../components/hockey"
    export default {
        components: {
            hockey
        },
        data () {
            return {
                apiurl: process.env.VUE_APP_RPI_API_URL,
                teams: [],
                home: null,
                away: null,
                scene: {
                    resolution: {
                        name: "1080p",
                        x: 1920,
                        y: 1080
                    },
                    graphics: {
                        scoreboard: {
                            x: 0,
                            y: 0,
                            visible: true
                        },
                        player_info: {
                            x: 30,
                            y: 500,
                            visible: false
                        }
                    }
                },
                res: [
                    {
                        name: "1080p",
                        x: 1920,
                        y: 1080
                    },
                    {
                        name: "720p",
                        x: 1280,
                        y: 720
                    },
                    {
                        name: "480p",
                        x: 720,
                        y: 480
                    },
                    {
                        name: "4K",
                        x: 3840,
                        y: 2160
                    },
                ]
            }
        },
        async mounted () {
            const data = await fetch(`${process.env.VUE_APP_RPI_API_URL}/api/opponents?populate=*&sort=school`, {
                headers: {
                    "Authorization": process.env.VUE_APP_RPI_API_AUTH
                }
            }).then(res => res.json());
            this.teams = data.data
        },
        methods: {
            change_home: function () {
                this.$refs.scoreboard.b.home_team_info = this.home
                this.$refs.scoreboard.ws.scbd = this.$refs.scoreboard.b
                this.$refs.scoreboard.ws.sendWSAction('RefreshData')
            },
            change_away: function () {
                this.$refs.scoreboard.b.away_team_info = this.away
                this.$refs.scoreboard.ws.scbd = this.$refs.scoreboard.b
                this.$refs.scoreboard.ws.sendWSAction('RefreshData')
            },
            update_scene: function () {
                console.log(this.scene)
            }
        }
    }
</script>

<style scoped>
.logo{
    width: 10rem;
    height: auto;
}
</style>