/*

	Last rev: 10/28/19

	Authors: Liam McBain

	YAGOS

*/

// Timer objects

class Timer {
	// Pass duration in minutes and true to count down, false to count up
	constructor(dur, countdown, callback){
		this.update = callback || function(){};
		this.dur = dur;
		this.countdown = countdown || true;
		this.min = dur;
		this.sec = 0;
		this._ms = 0;
		this.prev = new Date().getTime();
		this.elap = 0;
		this.total = dur*60000;
		this.pause = true;
		this.done = false;
		this.start = function(){
			this.pause = false;
			this.prev = new Date().getTime();
			this.count();
		}
		this.stop = function(){
			this.pause = true;
		}
		this.reset = function(){
			this.countdown = countdown || true;
			this.min = this.dur;
			this.sec = 0;
			this._ms = 0;
			this.prev = new Date().getTime();
			this.elap = 0;
			this.total = this.dur*60000;
			this.pause = true;
			this.done = false;
		}
		this.count = function(){
			if (this.elap >= (this.total)-100){
				this.stop();
				this.done = true;
			}
			else{
				this.done = false;
			}

			if (this.pause){
				return true;
			}

			//this.elap = this.total - (this.min*60000 + this.sec*1000 + this.ms*1000);

			var now = new Date().getTime();
			// diff will be in units 'ms'
			var diff = (now - this.prev);
			this.prev = now;

			this.elap = this.elap + diff;

			if (this.countdown){
				this.min = Math.floor((this.total - this.elap)/60000);
				this.sec = Math.floor((this.total - this.elap)/1000) - 60*this.min;
				this.ms = Math.floor((this.total - this.elap)/100)%10;
			}
			else{
				this.min = Math.floor(this.elap/60000);
				this.sec = Math.floor(this.elap/1000) - 60*this.min;
				this.ms = Math.floor(this.elap/100)%10;
			}


			// NEED TO UPDATE TIMER TO NOT GO NEGATIVE

			var that = this;
			setTimeout(function() {
    			that.count();
  			}, 100);

  			// For debugging purposes
  			//console.log(`${this.min}:${this.sec}:${this.ms}`);
		}
		this.updateclock = function(){
			if (this.countdown){
				this.min = Math.floor((this.total - this.elap)/60000);
				this.sec = Math.floor((this.total - this.elap)/1000) - 60*this.min;
				this.ms = Math.floor((this.total - this.elap)/100)%10;
			}
			else{
				this.min = Math.floor(this.elap/60000);
				this.sec = Math.floor(this.elap/1000) - 60*this.min;
				this.ms = Math.floor(this.elap/100)%10;
			}
		}

	}
	AdjustClock = function(data){
		var m
		switch(data.type){
			case 'min':
				m=60000
			break;
			case 'sec':
				m=1000
			break;
			case 'ms':
				m=100
			break;
			default:
				m=0
			break
		}
		var o
		switch(data.add){
			case false:
				o=1
			break;
			default:
				o=-1
			break;
		}
		var adj = data.value*m*o
		if(adj+this.elap <= this.total){
			this.elap = this.elap + adj
		}
		this.update();
    }
	set ms(x){
		this._ms = x;
		this.update();
	}
	get ms(){
		return this._ms;
	}
}


/*

Nomenclature for variable names in classes:

Variables will be a one-word, lowercase
indicator

If the variable is specific to a home or away
team, it will be preceded by either "home" or
"away" followed by "_".

*/

class PenaltyTimer {

	constructor( minutes , starttime , number , infraction ){

		// Pass the elapsed time of the main clock into 'starttime'
		this.initial = starttime;

		// Amount of ms needed to pass before time is over
		this.finish = minutes * 60000;
		this.number = number;
		this.infraction = infraction;

	}
}

export { Timer, PenaltyTimer }