<template>   
    <div>
        <div ref='graphic' v-html="img"></div>
    </div>
</template>

<script>
    import * as Graphic from '../yagos-lib/Graphic'
    export default{
        name: "graphic",
        data () {
            return{
                msg: "hi",
                graphic: "<svg></svg>",
                upd: new Graphic.GraphicUpdater(this),
                ready: false
            }
        },
        props: {
            updater: {
                type: Boolean,
                default: true
            },
            realtime: {
                type: Boolean,
                default: false
            },
            img: String
        },
        async beforeCreate () {
            
        },
        async mounted () {
        },
        methods: {
        }
    }
</script>

<style scoped>
</style>