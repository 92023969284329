<template>   
    <div class="wrapper container">
        <!-- TOP SECTION; SCORES AND TIME -->
        <div class="row">
            <!-- HOME SCORE AND SHOTS -->
            <div class="col">
                <div class="row">
                    <div class="col">
                        <h2 v-if="b.home_team_info.attributes.opp_name">{{b.home_team_info.attributes.opp_name}}</h2>
                        <h2 v-else>Home</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col"> <button class="btn btn-success" @click="ws.sendWSAction('HomeShotsUp')">+</button></div>
                    <div class="col"> <button class="btn btn-success" @click="ws.sendWSAction('HomeScoreUp')" block>+</button></div>
                </div>
                <div class="row">
                    <div class="col"><h1>{{b.home_shots}}</h1></div>
                    <div class="col"><h1>{{b.home_score}}</h1></div>
                </div>
                <div class="row">
                    <div class="col"> <button class="btn btn-success" @click="ws.sendWSAction('HomeShotsDown')" block>-</button></div>
                    <div class="col"> <button class="btn btn-success" @click="ws.sendWSAction('HomeScoreDown')" block>-</button></div>
                </div>
                <div class="row">
                    <div class="col"><h4>Shots</h4></div>
                    <div class="col"><h4>Score</h4></div>
                </div>
            </div>
            <!-- TIMER CONTROL -->
            <div class="col">
                <!-- BUTTON -->
                <div class="row"> <button class="btn btn-primary" @click="ws.sendWSAction('StartStopClock')" block>{{b.clock.pause?'Start' : 'Stop'}}</button></div>
                <!-- CLOCK -->
                <div class="row">
                    <div class="col">
                        <div class="row"><div class="col"> <button class="btn btn-primary" @click="ws.sendWSAction('AdjustClock',{type:'min',value:10,add:true})" block>+</button></div></div>
                        <div class="row"><div class="col"><h1>{{Math.floor(b.clock.min/10)}}</h1></div></div>
                        <div class="row"><div class="col"> <button class="btn btn-primary" @click="ws.sendWSAction('AdjustClock',{type:'min',value:10,add:false})" block>-</button></div></div>
                    </div>
                    <div class="col">
                        <div class="row"><div class="col"> <button class="btn btn-primary" @click="ws.sendWSAction('AdjustClock',{type:'min',value:1,add:true})" block>+</button></div></div>
                        <div class="row"><div class="col"><h1>{{b.clock.min%10}}</h1></div></div>
                        <div class="row"><div class="col"> <button class="btn btn-primary" @click="ws.sendWSAction('AdjustClock',{type:'min',value:1,add:false})" block>-</button></div></div>
                    </div>

                    <div class="col">
                        <div class="row"></div>
                    </div>

                    <div class="col">
                        <div class="row"><div class="col"> <button class="btn btn-primary" @click="ws.sendWSAction('AdjustClock',{type:'sec',value:10,add:true})" block>+</button></div></div>
                        <div class="row"><div class="col"><h1>{{Math.floor(b.clock.sec/10)}}</h1></div></div>
                        <div class="row"><div class="col"> <button class="btn btn-primary" @click="ws.sendWSAction('AdjustClock',{type:'sec',value:10,add:false})" block>-</button></div></div>
                    </div>
                    <div class="col">
                        <div class="row"><div class="col"> <button class="btn btn-primary" @click="ws.sendWSAction('AdjustClock',{type:'sec',value:1,add:true})" block>+</button></div></div>
                        <div class="row"><div class="col"><h1>{{b.clock.sec%10}}</h1></div></div>
                        <div class="row"><div class="col"> <button class="btn btn-primary" @click="ws.sendWSAction('AdjustClock',{type:'sec',value:1,add:false})" block>-</button></div></div>
                    </div>

                    <div class="col">
                        <div class="row"><div class="col"><h1>.</h1></div></div>
                    </div>

                    <div class="col">
                        <div class="row"><div class="col"> <button class="btn btn-primary" @click="ws.sendWSAction('AdjustClock',{type:'ms',value:1,add:true})" block>+</button></div></div>
                        <div class="row"><div class="col"><h1>{{b.clock.ms}}</h1></div></div>
                        <div class="row"><div class="col"> <button class="btn btn-primary" @click="ws.sendWSAction('AdjustClock',{type:'ms',value:1,add:false})" block>-</button></div></div>
                    </div>
                </div>
                <div class="row"><div class="col" block><h2>{{`${convertPeriodText(b.period)}`}}</h2></div></div>
            </div>
            <!-- AWAY SCORE AND SHOTS -->
            <div class="col">
                <div class="row">
                    <h2 v-if="b.away_team_info.attributes.opp_name">{{b.away_team_info.attributes.opp_name}}</h2>
                    <h2 v-else>Away</h2>
                </div>
                <div class="row">
                    <div class="col"> <button class="btn btn-danger" @click="ws.sendWSAction('AwayScoreUp')" block>+</button></div>
                    <div class="col"> <button class="btn btn-danger" @click="ws.sendWSAction('AwayShotsUp')" block>+</button></div>
                </div>
                <div class="row">
                    <div class="col"><h1>{{this.b.away_score}}</h1></div>
                    <div class="col"><h1>{{this.b.away_shots}}</h1></div>
                </div>
                <div class="row">
                    <div class="col"> <button class="btn btn-danger" @click="ws.sendWSAction('AwayScoreDown')" block>-</button></div>
                    <div class="col"> <button class="btn btn-danger" @click="ws.sendWSAction('AwayShotsDown')" block>-</button></div>
                </div>
                <div class="row">
                    <div class="col"><h4>Score</h4></div>
                    <div class="col"><h4>Shots</h4></div>
                </div>
            </div>
        </div>
        <!-- MIDDLE SECTION; PENALTIES -->
        <div class="row">
            <!-- HOME PENALTIES -->
            <div class="col">
                <div class="row">
                    <div class="col">
                        <select class="form-select" v-model="home_penalty.duration">
                            <option v-for="pl in penalty_lengths" :value="pl.value">{{pl.text}}</option>
                        </select>
                    </div>
                    <div class="col">
                        <select class="form-select" v-model="home_penalty.infraction">
                            <option v-for="p in penalties" :value="p">{{p}}</option>
                        </select>
                    </div>
                    <div class="col">
                        <select class="form-select" v-model="home_penalty.number">
                            <option v-for="r in b.home_team_roster.map(x=>Object.values(x)[0])" :value="r">{{r}}</option>
                        </select>
                    </div>
                    <div class="col"> <button class="btn btn-success" block @click="ws.sendWSAction('AddHomePenalty',home_penalty)">Add Penalty</button></div>
                </div>
                <div class="row" v-for="pen in b.home_penalties">
                    <div class="col"><p>{{formatPenaltyClock(pen.finish+pen.initial,b.clock.elap)}}</p></div>
                    <div class="col"><p>{{pen.infraction}}</p></div>
                    <div class="col"><p>{{pen.number}}</p></div>
                    <div class="col"> <button class="btn btn-success" block @click="ws.sendWSAction('RemoveHomePenalty',ind)">X</button></div>
                </div>
            </div>
            <!-- AWAY PENALTIES -->
            <div class="col">
                <div class="row">
                    <div class="col">
                        <select class="form-select" v-model="away_penalty.duration">
                            <option v-for="pl in penalty_lengths" :value="pl.value">{{pl.text}}</option>
                        </select>
                    </div>
                    <div class="col">
                        <select class="form-select" v-model="away_penalty.infraction">
                            <option v-for="p in penalties" :value="p">{{p}}</option>
                        </select>
                    </div>
                    <div class="col">
                        <select class="form-select" v-model="away_penalty.number">
                            <option v-for="r in b.away_team_roster.map(x=>Object.values(x)[0])" :value="r">{{r}}</option>
                        </select>
                    </div>
                    <div class="col"> <button class="btn btn-danger" block @click="ws.sendWSAction('AddAwayPenalty',away_penalty)">Add Penalty</button></div>
                </div>
                <div class="row" v-for="pen in b.away_penalties">
                    <div class="col"><p>{{formatPenaltyClock(pen.finish+pen.initial,b.clock.elap)}}</p></div>
                    <div class="col"><p>{{pen.infraction}}</p></div>
                    <div class="col"><p>{{pen.number}}</p></div>
                    <div class="col"> <button class="btn btn-danger" block @click="ws.sendWSAction('RemoveAwayPenalty',ind)">X</button></div>
                </div>
            </div>
        </div>
        <!-- BOTTOM SECTION; ACTION BUTTONS -->
        <div class="row">
            <div class="col">
                <div class="row"><div class="col"> <button class="btn btn-success" @click="ws.sendWSAction('HomeTimeout')" block>Timeout</button></div></div>
                <div class="row"><div class="col"> <button class="btn btn-success" @click="ws.sendWSAction('HomeChallenge')" block>Challenge</button></div></div>
                <div class="row"><div class="col"> <button class="btn btn-success" @click="ws.sendWSAction('HomeEmptyNet')" block>Empty Net</button></div></div>
            </div>
            <div class="col">
                 <button class="btn btn-success" @click="ws.sendWSAction('HomeGoal')" block>Goal</button>
            </div>
            <div class="col">
                <div class="row"><div class="col"> <button class="btn btn-primary" block @click="ws.sendWSAction('PeriodUp')">Period Up</button></div></div>
                <div class="row"><div class="col"> <button class="btn btn-primary" block @click="ws.sendWSAction('PeriodDown')">Period Down</button></div></div>
                <div class="row"><div class="col"> <button class="btn btn-primary" block @click="ws.sendWSAction('ResetClock')">Reset Clock</button></div></div>
            </div>
            <div class="col">
                 <button class="btn btn-danger" @click="ws.sendWSAction('AwayGoal')" block>Goal</button>
            </div>
            <div class="col">
                <div class="row"><div class="col"> <button class="btn btn-danger" @click="ws.sendWSAction('AwayTimeout')" block>Timeout</button></div></div>
                <div class="row"><div class="col"> <button class="btn btn-danger" @click="ws.sendWSAction('AwayChallenge')" block>Challenge</button></div></div>
                <div class="row"><div class="col"> <button class="btn btn-danger" @click="ws.sendWSAction('AwayEmptyNet')" block>Empty Net</button></div></div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as Score from '../yagos-lib/Scoreboard'
    import * as Data from '../yagos-lib/Data'
    export default{
        components: {
        },
        data () {
            
            var that=this
            console.log(process.env.VUE_APP_WS_URL)
            var ws = new Data.WebSocketHandler(process.env.VUE_APP_WS_URL,{user_id:0,token:0},0,
                    function(pd){
                        console.log(pd.action)
                        that.ws.scbd = that.b
                        if(pd.action=='RefreshData'&&pd.start=='True'){
                            var n = pd.body
                            console.log(pd.body)
                            var nemp = new Score.Hockey(n._period_length,n._overtime_length)
                            that.b = Score.assign_remove_functions(nemp,n)
                            if(!that.b.clock.pause){
                                that.b.clock.start()
                            }
                            that.b.HandleEvent(pd.action)
                            that.$parent.home = pd.body.home_team_info
                            that.$parent.away = pd.body.away_team_info
                        }
                        that.b.HandleEvent(pd.action,pd.params)
                    },
                    function(pd){
                        that.ws.scbd = that.b
                        that.b.HandleEvent(pd.action,pd.params)
                    })
            var t = new Score.Hockey()
            var temp = new Score.Hockey(t._period_length,t._overtime_length)
            return{
                b: new Score.Hockey(20,20),
                old: Score.assign_remove_functions(temp,t),
                teams: ['home','away'],
                home_penalty: {
                    duration: 2,
                    number: 0,
                    infraction: ""
                },
                away_penalty: {
                    duration: 2,
                    number: 0,
                    infraction: ""
                },
                penalties: ["Tripping","Cross Checking","Slashing","Hooking","Roughing","Boarding","Charging","Hit from behind","Hit to the head","Spearing","Misconduct","Fighting"],
                penalty_lengths: [
                    {value:2, text: "2:00"},
                    {value:4, text: "4:00"},
                    {value:5, text: "5:00"}
                ],
                positions: [
                    {value:'Forward', text: "F"},
                    {value:'Center', text: "C"},
                    {value:'Left Wing', text: "LW"},
                    {value:'Right Wing', text: "RW"},
                    {value:'Defense', text: "D"},
                    {value:'Right Defense', text: "RD"},
                    {value:'Left Defense', text: "LD"},
                    {value:'Goaltender', text: "G"},
                ],
                ws: ws,
                ping: null
            }
        },
        props: {
            index: Number,
            game_id: Number
        },
        beforeCreate () {
            
        },
        mounted () {
            
            this.ws.game_id=this.game_id
            this.ws.newWSConnection()
            this.ws.retryWSConnection()
            this.ping = setInterval(()=>{
                this.ws.sendWSInformation('ping')
                console.log('ping')
            },10000)
        },
        methods:{
            convertPeriodText(period){
                if(period==1){
                    return `1st Period`
                }
                if(period==2){
                    return `2nd Period`
                }
                if(period==3){
                    return `3rd Period`
                }
                if(period==4){
                    return `OT`
                }
                else{
                    return `OT${period-3}`
                }
            },
            addPlayerToRoster(team){
                this.b[`${team}_team_roster`].push({})
            },
            onFileChange(e,v,i){
                const file = e.target.files[0]
                this.b[`${v}_team_info`][`logo${i}`] = URL.createObjectURL(file)
            },
            formatPenaltyClock(fin,elap){
                var m=Math.floor((fin-elap)/60000)
                var s=Math.floor((fin-elap)/1000)%60
                if(s<10){return `${m}:0${s}`}
                else{return `${m}:${s}`}
            }
        }
        
    }
</script>

<style scoped>
:is(h1, h2, h3, h4, h5, h6) {
    text-align: center;
    padding-right:0px;
    padding-left:0px;
}
.time{
    padding-right:0px;
    padding-left:0px;
}
.newpenalty{
    background-color: lightcoral;
}
button{
    height:100%;
}
.sporthome{
    flex: 1 1 auto;
}
</style>