import * as Time from '../Time'
import * as G from '../Graphic'
import $ from 'jquery'

class Scoreboard {

	constructor ( period_length, overtime_length, timer_callback, update_callback, event_callback, refresh_callback ){
		// Sets function to be called for any type that
		// requires external update (timers, etc.)
        this.name = ''
        this.sport = 'Hockey'
        this._period_length = period_length || 20;
        this._overtime_length = overtime_length || 20;

		this._timer = timer_callback || function(){};
        this._update = update_callback || function(){};
        this._event = event_callback || function(){};
        this._refresh = refresh_callback || function(){};

		this.clock = new Time.Timer( this._period_length , true , this._timer );

		// Team info

		this.home_team_info = {attributes:{
            primary_logo:{data:{attributes:{formats:{
                large:{},small:{},medium:{},thumbnail:{}
            }}}},
            secondary_logo:{data:{attributes:{formats:{
                large:{},small:{},medium:{},thumbnail:{}
            }}}}
        }};
		this.away_team_info = {attributes:{
            primary_logo:{data:{attributes:{formats:{
                large:{},small:{},medium:{},thumbnail:{}
            }}}},
            secondary_logo:{data:{attributes:{formats:{
                large:{},small:{},medium:{},thumbnail:{}
            }}}}
        }};

		// Roster for team sports

		this.home_team_roster = [];
		this.away_team_roster = [];

		this._home_score = 0;
		this._away_score = 0;

		this._home_shots = 0;
		this._away_shots = 0;

		this._home_shots_on_goal = 0;
		this._away_shots_on_goal = 0;

		this.home_penalties = [];
		this.away_penalties = [];

		this._period = 1;

        this.home_timeout = false;
		this.away_timeout = false;

        this.home_challenge = false;
		this.away_challenge = false;
		
		this.home_empty_net = false;
		this.away_empty_net = false;

		this.home_delayed_penalty = false;
		this.away_delayed_penalty = false;

		var that = this;

		// Delete penalties from arrays

		this.DeletePenalties = function (){

			var new_home_pen = [];
			var new_away_pen = [];

			this.home_penalties.forEach(function(item,index){

				if (item.initial + item.finish > that.clock.elap+1000){
					new_home_pen.push(item);
				}

			})

			this.away_penalties.forEach(function(item,index){

				if (item.initial + item.finish > that.clock.elap+1000){
					new_away_pen.push(item);
				}

			})

			this.home_penalties=new_home_pen;
			this.away_penalties=new_away_pen;
		}

	    setInterval( function() {

	      	that.DeletePenalties();

	    }, 1000 );

        //Create getters and setters for all properties that will use the update callback
        for (const property in this){
            //console.log(`${property}:${that[property]}`)
            switch(typeof(this[property])){
                case('number'):
                    Object.defineProperty(this,property.substring(1),{
                        get:function(){
                            return this[`${property}`]
                        },
                        set:function(val){
                            this[`${property}`] = val
                            this._update()
                        }
                    })
                break;
            }
        }

	}

    RefreshData = function (){
        this._refresh()
        this._update()
    }

    // Game functions

    StartClock = function (){
        this.clock.start()
    }

    StopClock = function (){
        this.clock.stop()
    }

    ResetClock = function (){
        this.clock.reset()
    }

    StartStopClock = function (){
        if(this.clock.pause){this.clock.start()}
        else{this.clock.stop()}
    }

    PeriodUp = function(){
        this.period += 1
    }

    PeriodDown = function(){
        if(this.period>1){
            this.period -= 1
        }
    }

    // Score and shots

    HomeScoreUp = function () {
        this.home_score += 1
    }

    HomeShotsUp = function () {
        this.home_shots += 1
    }

    AwayScoreUp = function () {
        this.away_score += 1
    }

    AwayShotsUp = function () {
        this.away_shots += 1
    }

    HomeScoreDown = function () {
        if(this.home_score>0){this.home_score -= 1}
    }

    HomeShotsDown = function () {
        if(this.home_shots>0){this.home_shots -= 1}
    }

    AwayScoreDown = function () {
        if(this.away_score>0){this.away_score -= 1}
    }

    AwayShotsDown = function () {
        if(this.away_shots>0){this.away_shots -= 1}
    }

    NextPeriod = function(){
        this._period += 1
        if(this._period>3){this.clock = new Time.Timer(this._overtime_length,true,this._timer)}
        else{this.clock = new Time.Timer(this._period_length,true,this._timer)}
        this._update()
    }

    HomeGoal = function(){
        this.home_score += 1
        this._event("home_goal")
    }

    AwayGoal = function(){
        this.away_score += 1
        this._event("away_goal")
    }

    HomeTimeout = function(){
        this.home_timeout = !this.home_timeout
        this._event('home_timeout')
    }

    HomeChallenge = function(){
        this.home_challenge = !this.home_challenge
        this._event('home_challenge')
    }

    HomeEmptyNet = function(){
        this.home_empty_net = !this.home_empty_net
        this._event('home_empty_net')
    }

    HomeDelayedPenalty = function(){
        this.home_delayed_penalty = !this.home_delayed_penalty
        this._event('home_delayed_penalty')
    }

    AwayTimeout = function(){
        this.away_timeout = !this.away_timeout
        this._event('away_timeout')
    }

    AwayChallenge = function(){
        this.away_challenge = !this.away_challenge
        this._event('away_challenge')
    }

    AwayEmptyNet = function(){
        this.away_empty_net = !this.away_empty_net
        this._event('away_empty_net')
    }

    AwayDelayedPenalty = function(){
        this.away_delayed_penalty = !this.away_delayed_penalty
        this._event('away_delayed_penalty')
    }

    AddHomePenalty = function(data){
        this.home_penalties.push(new Time.PenaltyTimer(data.duration,this.clock.elap,data.number,data.infraction))
    }

    AddAwayPenalty = function(data){
        this.away_penalties.push(new Time.PenaltyTimer(data.duration,this.clock.elap,data.number,data.infraction))
    }

    RemoveHomePenalty = function(ind){
        this.home_penalties.splice(ind,1)
    }

    RemoveAwayPenalty = function(ind){
        this.away_penalties.splice(ind,1)
    }
    
    AdjustClock = function(data){
        this.clock.AdjustClock(data)
        this._update()
    }

    HandleEvent = function(event,data){
        this[event](data);
    }

    set timer(x){
		this._timer = x;
		this.clock.update = x;
	}

	set update(x){
		this._update = x;
	}

	set event(x){
		this._event = x;
	}

    set refresh(x){
		this._refresh = x;
	}

}

// Future addition to bind to React or Vue elements so as not to worry about update callbacks
function SetBinds( dom , callback ) {
    //console.log(dom.graphic)
    var $div = $('<div>').html(dom.graphic)
    $div.find('.home_score').html('{{this.$parent.scoreboard.home_score}}')
    dom.graphic = $div.html()
	return
}

function UpdateScoreboard( scbd , dom ) {
    G.update_text(scbd.home_score,'home_score',dom)
    G.update_text(scbd.away_score,'away_score',dom)
    G.update_text(scbd.home_shots,'home_shots',dom)
    G.update_text(scbd.away_shots,'away_shots',dom)
    G.update_text(scbd.home_shots_on_goal,'home_shots_on_goal',dom)
    G.update_text(scbd.away_shots_on_goal,'away_shots_on_goal',dom)
    // Format period
    var p = scbd.period
    var txt;
	switch(p){
		case 1:
			txt = '1st';
		break;
		case 2:
			txt = '2nd';
		break;
		case 3:
			txt = '3rd';
		break;
		case 4:
			txt = 'OT';
		break;
		default:
			if(p>4){
				txt = (p-3).toString() + "OT";
			}
			else{txt='1st';}
		break;
	}
    G.update_text(txt,"period_text",dom);
    UpdateTimers(scbd,dom)
    
}

function UpdateTimers( scbd , dom ) {
    G.update_text(G.FormatClock(scbd.clock),'clock_text',dom)
    var t = ["home", "away"];

    // Format penalty timers
    var times = [];
    for(var i=0;i<t.length;i++){
        scbd[t[i]+"_penalties"].forEach(function(pen,index){
            var rem = pen.initial + pen.finish - scbd.clock.elap;
            times.push(rem);
        });
    }
    if(times.length>0){
        var time = times.reduce(function(a, b) {
            return Math.min(a, b);
        });
    }
    var min = Math.floor((time)/60000);
    var sec = Math.floor(time/1000) - 60*min;
    if(sec>9){
        G.update_text(`${min}:${sec}`,"penaltyClock",dom);
    }
    else{
        G.update_text(`${min}:0${sec}`,"penaltyClock",dom);
    }
    var hp = scbd["home_penalties"].length;
    var ap = scbd["away_penalties"].length;

    if(hp == 0 && ap == 0){
        G.hide("home_penalty",dom);
        G.hide("away_penalty",dom);
        G.hide("even_penalty",dom);
    }

    else if(hp == 1 && ap == 0){
        G.update_text(`PP`,"penaltyText",dom);
        G.show("home_penalty",dom);
        G.hide("away_penalty",dom);
        G.hide("even_penalty",dom);
    }

    else if(hp > 1 && ap == 1){
        G.update_text(`4 on 3`,"penaltyText",dom);
        G.show("home_penalty",dom);
        G.hide("away_penalty",dom);
        G.hide("even_penalty",dom);
    }

    else if(hp > 1 && ap == 0){
        G.update_text(`5 on 3`,"penaltyText",dom);
        G.show("home_penalty",dom);
        G.hide("away_penalty",dom);
        G.hide("even_penalty",dom);
    }

    else if(hp == 0 && ap == 1){
        G.update_text(`PP`,"penaltyText",dom);
        G.hide("home_penalty",dom);
        G.show("away_penalty",dom);
        G.hide("even_penalty",dom);
    }

    else if(hp == 1 && ap > 1){
        G.update_text(`4 on 3`,"penaltyText",dom);
        G.hide("home_penalty",dom);
        G.show("away_penalty",dom);
        G.hide("even_penalty",dom);
    }

    else if(hp == 0 && ap > 1){
        G.update_text(`5 on 3`,"penaltyText",dom);
        G.hide("home_penalty",dom);
        G.show("away_penalty",dom);
        G.hide("even_penalty",dom);
    }

    else if(hp == 1 && ap == 1){
        G.update_text(`4 on 4`,"penaltyText",dom);
        G.hide("home_penalty",dom);
        G.hide("away_penalty",dom);
        G.show("even_penalty",dom);
    }

    else if(hp > 1 && ap > 1){
        G.update_text(`3 on 3`,"penaltyText",dom);
        G.hide("home_penalty",dom);
        G.hide("away_penalty",dom);
        G.show("even_penalty",dom);
    }
}

export { Scoreboard, SetBinds, UpdateScoreboard, UpdateTimers }